<template>
  <!--
  <SnowFlake v-for="snowflake in snowflakes" :key="snowflake.id" :size="snowflake.size" :left="snowflake.left" :seconds="snowflake.seconds" />
  -->
  <AnnouncementBar  message='<a class="announcement-bar__link" href="https://untamd.co.uk/products/leo-pendant?_pos=1&_sid=2a6d67eb4&_ss=r">LEO SEASON SPECIAL! | Save 30% on LEO pendants</a>' autoClose :autoCloseTime="10000"/>
  <StarsBack v-for="star in stars" :key="star.id" :size="star.size" :left="star.left" :top="star.top"/>
  <BigTitle />
  <CartWheel />
  <div class="cart-form">
  <CartWheelForm />
  <CartDescription />
  </div>

</template>

<script>
import AnnouncementBar from './components/AnnouncementBar.vue';
import CartWheel from './components/CartWheel.vue';
import CartDescription from './components/CartDescription.vue';
import CartWheelForm from './components/CartWheelForm.vue';
import BigTitle from './components/BigTitle.vue';
import StarsBack from './components/StarsBack.vue';
//import SnowFlake from './components/SnowFlake.vue'

export default {
  name: 'ZodiacWheel',
  components:{
//      SnowFlake,
      StarsBack,
      BigTitle,
      CartWheel,
      CartWheelForm,
      CartDescription,
      AnnouncementBar,
  },
    data() {
    return {
      snowflakes: [],
      stars: []
    };
  },
  methods: {
      generateSnowflakes() {
    for (let i = 0; i < 100; i++) {
      const size = Math.random() * 20 + 5;
      const seconds = Math.floor(Math.random()*100);
      const left = Math.random() * window.innerWidth;
      this.snowflakes.push({ id: i, size, left, seconds });
      }},
      generateStars() {
    for (let i = 0; i < 100; i++) {
      const size = Math.random() * 5 + 5;
      const left = Math.random() * window.innerWidth;
      const top = Math.random() * window.innerHeight;
      this.stars.push({ id: i, size, left, top });
      }},
  },
  mounted() {
     // this.generateSnowflakes();
      this.generateStars();
  },
  }
</script>

<style>
.announcement-bar__link {
    width: 100%;
    text-decoration: underline;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.announcement-bar__link:hover {
    text-decoration: underline;
    color: #0c1628;
}

.announcement-bar__link:visited {
    color: #0c1628;
}
</style>
