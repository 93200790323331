<template>
  <div class="infinity-loader"></div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  console.log('Infinity Loader Mounted');
});
</script>

<style scoped>
.infinity-loader {
  width: 40px;
  height: 40px;
  border: 3px solid #EFE9D7;
  border-radius: 50%;
  border-top-color: transparent;
  border-right-color: transparent;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
