import { createStore } from 'vuex';


export default createStore({
  state: {
    desc: null,
    path: null,
    isLoading: false
  },
  mutations: {
    setDesc(state, desc, path) {
      state.desc = desc;
      state.path = path;
    },
     setGif(state, bool) {
      state.isLoading = bool;
    },
    clearDesc(state) {
      state.desc = null;
      state.path = null;
      state.isLoading = false;

    }
  },
  actions: {
    spin({ commit }, desc, path) {
      commit('setDesc', desc, path);
    },
    loadAct({ commit }, bool) {
      commit('setGif', bool);
    },
    spinOff({ commit }) {
      commit('clearDesc');
    }
  },
  getters: {
  description(state) {
    return state.desc;
  },
    path(state) {
    return state.path;
  },
  loading(state) {
    return state.isLoading;
  }
    }
});