<template>
  <div class="announcement-bar" v-if="showBar">
    <p>
      <span class="message" v-html="message">
      </span>
            <span>
      <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" class="icon-arrow" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
</path></svg>
</span>
      <button @click="closeBar" class="close-button">
      <div>
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" style="height: 1rem;" fill="none" viewBox="0 0 18 17">
  <path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor">
</path></svg></div>
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AnnouncementBar',
  props: {
    message: {
      type: String,
      default: 'This is an <a href="#">announcement!</a>'
    }
  },
  data() {
    return {
      showBar: true
    };
  },
  methods: {
    closeBar() {
      this.showBar = false;
    }
  }
};
</script>

<style scoped>
.announcement-bar {
  text-decoration-color: #c1628;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #FAE7B9;
  color: #721c24;
  text-align: center;
  /* padding: 10px; */
  /* border: 1px solid #f5c6cb;  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
letter-spacing: .2rem;
font-family: "Avenir Next", sans-serif;
font: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    padding: 1rem 0;
    margin: 0;
    letter-spacing: .1rem;
    min-height: 3.8rem;
  font-size: calc(1 * 68.5%);
    color: #0c1628;
}

a {
  text-decoration: underline;
  text-decoration-color: #c1628;
      color: #0c1628;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 16px;
  position: absolute;
  right: 2vw;
  cursor: pointer;
  color: #c1628;
}

.announcement-bar .icon-arrow {
    display: inline-block;
    display:none;
    pointer-events: none;
    margin-left: .8rem;
    vertical-align: middle;
    margin-bottom: .2rem;
}

.icon-arrow {
    width: 1.5rem;
}
@media screen and (max-width: 768px) {
.announcement-bar .icon-arrow{
display:none;
}
.close-button{
    display: none;
    }
   .announcement-bar{
   position:absolute;
   z-index:10;
   }
}

@media (min-height: 1800px){
.announcement-bar .icon-arrow{
display:none;
}
.close-button{
    display: none;
    }
   .announcement-bar{
   position:absolute;
   z-index:10;
   }
}
</style>
