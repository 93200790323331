<template>
  <div class="starback" :style="{ fontSize: size + 'px', left: left + 'px', top: top + 'px'}">
    ★
  </div>
</template>

<script>
export default {
  name: 'StarsBack',
  props: {
    size: Number,
    left: Number,
    top: Number
  }
}
</script>

<style scoped>
.starback {
  position: absolute;
  color: white;
  user-select: none;
}

</style>
