<template>
<div id="solid-wheels-container">
        <div id="indicator"></div>
        <div id="solid-wheel-0"></div>
        <div id="solid-wheel-1"></div>
        <div id="solid-wheel-2"></div>
        <div id="solid-wheel-3"></div>
        <div id="solid-wheel-4"></div>
        <div id="solid-wheel-5"></div>
        <div id="solid-wheel-6"></div>
        <div id="number-wheel">
            <div v-for="item in transformedNumbers" :key="item.number" class="item-day" :style="{ transform: item.transformStyle }">{{ item.number }}</div>
        </div>
        <div id="month-wheel">
            <div v-for="item in transformedMonths" :key="item.month" class="item-month" :style="{ transform: item.transformStyle }">{{ item.month }}</div>
        </div>
        <div id="zodiac-sign-wheel">
            <div v-for="item in transformedSignPaths" :key="item.data" :class="item.class" :style="{ transform: item.transformStyle, backgroundImage: 'url(' + item.data + ')' }">
            </div>
        </div>
        <div id="zodiac-name-wheel">
            <div v-for="zodiac in transformedZodiacNames" :key="zodiac.transformStyle" class="item-zodiac-name" :style="{ transform: zodiac.transformStyle }">
                <div class="name-wrapper" :style="{ transform: zodiac.nameWrapperTransform, display: 'flex', justifyContent: 'center', width: '50px', position: 'absolute' }">
                    <div v-for="letter in zodiac.letters" :key="letter.content" class="letter" :style="{ position: 'absolute', fontSize: letter.size, left: letter.left, top: letter.top, transform: letter.transform}">
                        {{ letter.content }}
                    </div>
                </div>
            </div>
        </div>
        <div id="graduated-wheel">
            <div v-for="item in transformedLines" :key="item.line" class="item-line" :style="{ transform: item.transformStyle }">{{ item.line }}</div>
        </div>
        <div id="zodiac-symbol-wheel">
            <div v-for="item in transformedSymbolPaths" :key="item.data" :class="item.class" :style="{ transform: item.transformStyle, backgroundImage: 'url(' + item.data + ')' }">
            </div>
        </div>
        <div id="constellation-wheel"><img id="constellation"></div>
            <svg id="hexagon" xmlns="http://www.w3.org/2000/svg" width="400px" height="400px" viewBox="0 0 200 200" version="1.1">
            <polygon style="fill:none;stroke:gold;stroke-width:1.0px" points="148,183.138438763306 52,183.138438763306 4,100 52,16.8615612366939 148,16.8615612366939 196,100"></polygon>
            </svg>
        </div>

</template>

<script>
let symbolPaths = ['/img/StarSigns/Capricorn/capricorn-lineart.svg',
'/img/StarSigns/Aquarius/aquarius-lineart.svg',
'/img/StarSigns/Pisces/pisces-lineart.svg',
'/img/StarSigns/Aries/aries-linework.svg',
'/img/StarSigns/Taurus/taurus-lineart.svg',
'/img/StarSigns/Gemini/gemini-lineart.svg',
'/img/StarSigns/Cancer/cancer-lineart.svg',
'/img/StarSigns/Leo/leo-lineart.svg',
'/img/StarSigns/Virgo/virgo-lineart.svg',
'/img/StarSigns/Libra/libra-lineart.svg',
'/img/StarSigns/Scorpio/scorpio-lineart.svg',
'/img/StarSigns/Saggitarius/sagittarius-lineart.svg']

let signPaths = ['/img/svg/Capricorn-Brush.svg',
'/img/svg/Aquarius-Brush.svg',
'/img/svg/Pisces-Brush.svg',
'/img/svg/Aries-Brush.svg',
'/img/svg/Taurus-Brush.svg',
'/img/svg/Gemini-Brush.svg',
'/img/svg/Cancer-Brush.svg',
'/img/svg/Leo-Brush.svg',
'/img/svg/Virgo-Brush.svg',
'/img/svg/Libra-Brush.svg',
'/img/svg/Scorpio-Brush.svg',
'/img/svg/Sagittarius-Brush.svg']

//let viewportWidth = window.innerWidth;

let numbers = ['I','II','III','IV','V','VI','VII','VIII','IX','X','XI','XII','XIII','XIV','XV','XVI','XVII','XVIII','XIX','XX','XXI','XXII','XXIII','XXIV','XXV','XXVI','XXVII','XXVIII','XXIX','XXX','XXXI']
const lines = Array(365).fill('|');
let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let zodiacNames = ["Capricorn", "Aquarius", "Pisces", "Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo", "Libra", "Scorpio", "Sagittarius"];


export default {
  name: 'CartWheel',
  data() {
    return{
      StrokeColour: 'Black',
      transformedNumbers: [],
      transformedMonths: [],
      transformedLines: [],
      transformedSymbolPaths: [],
      transformedSignPaths: [],
      transformedZodiacNames: [],
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight
    }
  },
  props: {
    msg: String
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth, this.viewportHeight);
    //let transformStyleName = 0;
    this.transformedNumbers = numbers.map((number, index) => {
      let itemAngle = ((360 / 31) * index) - 92;
      let fasele = this.viewportWidth < 768 || this.viewportHeight > 1800 ? (this.viewportWidth*36.5)/100 : 250;
      let transformStyle = `rotate(${itemAngle}deg) translateX(${fasele}px) rotate(90deg)`;

      return {
         number: number,
         transformStyle: transformStyle
        };
    });

    this.transformedMonths = months.map((month, index) => {
      let itemAngle = ((360 / 12) * index) - 93;
      let fasele = this.viewportWidth < 768 || this.viewportHeight > 1800 ? (this.viewportWidth*47.5)/100 : 330;
      let transformStyle = `rotate(${itemAngle}deg) translateX(${fasele}px) rotate(93deg)`;

      return {
         month: month,
         transformStyle: transformStyle
        };
    });

    this.transformedLines = lines.map((line, index) => {
      let itemAngle = ((360 / 365) * index) - 93;
      let fasele = this.viewportWidth < 768 || this.viewportHeight > 1800 ? (this.viewportWidth*46)/100 : 310;
      let transformStyle = `rotate(${itemAngle}deg) translateX(${fasele}px) rotate(93deg)`;

      return {
         line: line,
         transformStyle: transformStyle
        };
    });

this.transformedZodiacNames = zodiacNames.map((zodiacName, index) => {
    let itemAngle = ((360 / 12) * index) - 225;
    let transformStyle = `rotate(${itemAngle}deg) translateX(0px)`;
    //transformStyleName = transformStyle;
    let nameWrapperTransform = 'rotate(460deg)';

    // The radius for the cartwheel effect
    let radius = this.viewportWidth < 768 || this.viewportHeight > 1800 ? ((this.viewportWidth*20.7)/100)+(400-this.viewportWidth)/25 : 130;


    let letters = [];

    for(let i = 0; i < zodiacName.length; i++) {
        let letterAngle = (50 / zodiacName.length) * i;
        let radian = letterAngle * (Math.PI / 180);
        let x =  radius * Math.cos(radian);
        let y =  radius * Math.sin(radian);
        let letterTransform = `rotate(${letterAngle +120}deg)`;
                    let fontSize = 10 ;

        if( letterAngle +120  < 171 && letterAngle +120 > 120 && y+radius > 110 && x+radius < 160) {
            fontSize = 5 ;
        }

        letters.push({
            content: zodiacName.charAt(i),
            left: `${(x + radius)}px`,
            top: `${(y + radius)}px`,
            transform: letterTransform,
            size: `${(fontSize)}px`
        });
    }

    return {
        transformStyle: transformStyle,
        nameWrapperTransform: nameWrapperTransform,
        letters: letters
    };
});


    this.transformedSymbolPaths = this.transformPaths(symbolPaths, "item-zodiac-symbol", this.viewportWidth < 768 || this.viewportHeight > 1800 ? (this.viewportWidth*27)/100 : 185);

    this.transformedSignPaths = this.transformPaths(signPaths, "item-zodiac-sign", this.viewportWidth < 768 || this.viewportHeight > 1800 ? (this.viewportWidth*18)/100 : 130);
},
methods:{
    updateWidth() {
       this.viewportWidth = window.innerWidth;
    },
    transformPaths(paths, className, distance) {
        return paths.map((path, index) => {
            let itemAngle = ((360 / 12) * index) + 270;
            let transformStyle = `rotate(${itemAngle}deg) translateX(${distance}px) rotate(90deg)`;

            return {
                class: className,
                data: path,
                transformStyle: transformStyle,
            };
        });
    }
}

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.item-zodiac-symbol {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
</style>
