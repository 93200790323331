/* global grecaptcha */
<template>
    <div id="wheel-buttons">
        <div class="right-hand-side">
        <form class="input-form" @submit.prevent="handleSubmit">
            <select @change="dayer" id="input-month" type="button" v-model="month" :class="['select', { 'warning': monthWarning }]">
                <option value="Select Month">Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
            </select>
            <p v-if="monthWarning" class="warning-message">Please select your month</p>
            <select id="input-day" v-model="day" type="button" class="select" v-if="month!='Select Month'">
                <option value="">Select Day</option>
                <option v-for="dayNumber in daysInSelectedMonth" :key="dayNumber" :value="dayNumber">{{ dayNumber }}</option>
            </select>
            <input id="share" @click="share" type="button" class="select mobile-only" value="Share">
            <input id="input-email" v-model="email" class="select" placeholder="Your Email">
            <p v-if="emailWarning" class="warning-message">Please enter you email address</p>
            <!--<div v-if="showCaptcha" class="g-recaptcha" data-sitekey="6Lc8am0oAAAAANS-013MK3tk_Ge5XOnt6ET_lacR"></div>-->
            <input id="rotate" type="submit" class="select" value="Spin!">
            </form>
            <p id="notif">By signing up you agree to receive marketing emails from us. Click <span><a style="color:red" href="https://untamd.co.uk/policies/privacy-policy">here</a></span> to view our privacy policy.
</p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref, onMounted } from 'vue';

import axios from "axios"

import formData from 'form-data';
import Mailgun from 'mailgun.js';

export default {
  name: 'ZodiacWheel',
  setup() {
    const recaptchaToken = ref('');  // Store the recaptcha token

    onMounted(() => {
      // Load reCAPTCHA v3 script
      const script = document.createElement('script');
      script.src = "https://www.google.com/recaptcha/api.js?render=YOUR_RECAPTCHA_V3_SITE_KEY";
      document.head.appendChild(script);
    });

    // Other reactive properties or methods...

    return {
      recaptchaToken
      // Other properties or methods...
    };
  },
  props: {
   horoscopeData: Object
  },
  computed: {
      ...mapState(['path', 'desc']),
    daysInSelectedMonth() {
      return Array.from({ length: this.dayer() }, (_, i) => i + 1);
    }
  },
  data() {
    return {
    email:'',
    attemptCount: 0,
            monthWarning: false,
            emailWarning: false,
      day: this.horoscopeData && this.horoscopeData.day ? this.horoscopeData.day : 1,
    month: this.horoscopeData && this.horoscopeData.month ? this.horoscopeData.month : 'Select Month',
            symbolPaths : ['assets/img/Star Sign Packs/Capricorn/capricorn-lineart.svg',
            'assets/img/Star Sign Packs/Aquarius/aquarius-lineart.svg',
            'assets/img/Star Sign Packs/Pisces/pisces-lineart.svg',
            'assets/img/Star Sign Packs/Aries/aries-linework.svg',
            'assets/img/Star Sign Packs/Taurus/taurus-lineart.svg',
            'assets/img/Star Sign Packs/Gemini/gemini-lineart.svg',
            'assets/img/Star Sign Packs/Cancer/cancer-lineart.svg',
            'assets/img/Star Sign Packs/Leo/leo-lineart.svg',
            'assets/img/Star Sign Packs/Virgo/virgo-lineart.svg',
            'assets/img/Star Sign Packs/Libra/libra-lineart.svg',
            'assets/img/Star Sign Packs/Scorpio/scorpio-lineart.svg',
            'assets/img/Star Sign Packs/Saggitarius/sagittarius-lineart.svg'],
            signPaths : ['assets/img/svg/Capricorn-Brush.svg',
            'assets/img/svg/Aquarius-Brush.svg',
            'assets/img/svg/Pisces-Brush.svg',
            'assets/img/svg/Aries-Brush.svg',
            'assets/img/svg/Taurus-Brush.svg',
            'assets/img/svg/Gemini-Brush.svg',
            'assets/img/svg/Cancer-Brush.svg',
            'assets/img/svg/Leo-Brush.svg',
            'assets/img/svg/Virgo-Brush.svg',
            'assets/img/svg/Libra-Brush.svg',
            'assets/img/svg/Scorpio-Brush.svg',
            'assets/img/svg/Sagittarius-Brush.svg'],
            viewportWidth : window.innerWidth,
            numbers : ['I','II','III','IV','V','VI','VII','VIII','IX','X','XI','XII','XIII','XIV','XV','XVI','XVII','XVIII','XIX','XX','XXI','XXII','XXIII','XXIV','XXV','XXVI','XXVII','XXVIII','XXIX','XXX','XXXI'],
            lines : [...Array(365).keys()].map(() => '|'),
            months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            zodiacNames : ["Capricorn", "Aquarius", "Pisces", "Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo", "Libra", "Scorpio", "Sagittarius"],
            numberWheel : document.querySelector("#number-wheel"),
            monthWheel : document.querySelector("#month-wheel"),
            zodiacNameWheel : document.querySelector("#zodiac-name-wheel"),
            zodiacSignWheel : document.querySelector("#zodiac-sign-wheel"),
            zodiacSymbolWheel : document.querySelector("#zodiac-symbol-wheel"),
            graduatedWheel : document.querySelector("#graduated-wheel"),
    }
  },
  watch: {
    month(newVal) {
        if (newVal !== "Select Month") {
            this.monthWarning = false;
        }
    },
    email(newVal) {
        if (newVal !== "") {
            this.emailWarning = false;
        }
    }
},
  methods: {
    async onLoginAttempt() {
      if (this.attemptCount >= 2) {
        const token = await this.getRecaptchaToken();
        console.log(token);
      } else {
        this.attemptLogin();
      }
    },
    attemptLogin() {
      // Simulate unsuccessful login
      this.attemptCount++;
      if (this.attemptCount >= 2) {
        alert("Showing CAPTCHA after 2 failed attempts!");
        this.onLoginAttempt();
      } else {
        alert("Failed login attempt: " + this.attemptCount);
      }
    },
    getRecaptchaToken() {
      return new Promise((resolve) => {
        grecaptcha.ready(() => {
          grecaptcha.execute('6Lc8am0oAAAAANS-013MK3tk_Ge5XOnt6ET_lacR', { action: 'login' }).then(token => {
            resolve(token);
          });
        });
      });
    },
  isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
},
    async share() {
      let link, description;
      const default_link = "https://zodiac.untamd.co.uk";
      const default_desc = "Please share me";

      const statePath = this.$store.state.path;
      const stateDesc = this.$store.state.desc;

      if (statePath && stateDesc) {
        link = `${default_link}/${statePath}`;
        description = stateDesc;
      } else {
        link = default_link;
        description = default_desc;
      }

      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Share Link',
            text: description,
            url: link,
          });
        } catch (err) {
          console.error('There was an error sharing:', err);
        }
      } else {
        console.log('Web Share API not supported.');
      // Fallback mechanism can be implemented here, e.g., copy link to clipboard or show a modal.
    }
    },
    dayer(){
        const inputMonthElement = document.getElementById("input-month");
    inputMonthElement.style.width = "75%";
      const monthDays = {
          January: 31,
          February: 28,
          March: 31,
          April: 30,
          May: 31,
          June: 30,
          July: 31,
          August: 31,
          September: 30,
          October: 31,
          November: 30,
          December: 31,
      };
      return monthDays[this.month];

    },
    async horoscope(){
       for (let attempt = 0; attempt < 3; attempt++) {
          try {
            this.$store.dispatch('spin', 'Our fortune teller began to scry into the crystal ball to uncover the mysteries of your future...','');
            this.$store.dispatch('loadAct', true);
            const response = await axios.post('/horoscope',
              {
               day: this.day,
               month: this.month,
               email: this.email
               });
            if (response.data) {
              // Save horoscope to localStorage
              let jsonString = response.data[0];
              console.log(jsonString)
              let jsonData = JSON.parse(jsonString);
              console.log(jsonData)
              let descValue = jsonData;
              console.log(descValue)
              this.$store.dispatch('spin', descValue,response.data[1]);
              this.$store.dispatch('loadAct', false);
              await this.sendEmailWithMailgun(this.email, response.data[1]);
              break; // Exit the loop if successful
            } else {
                  // Handle any other response, e.g., showing an error message
                  alert('Something went wrong. Retrying...');
                  // Optionally implement a delay here before retrying
            }
          }
          catch (error) {
            // Handle the error appropriately
            console.error('Error', error);
            if (attempt === 2) {
            alert('Something went wrong. Please try again');
            }
          }
       }
    },
    async sendEmailWithMailgun(recipientEmail, horoscopeDescription) {
    const mailgun = new Mailgun(formData);
    const mg = mailgun.client({username: 'api', key: '019dcf88623d2012f757c045fee46486-1c7e8847-41f3b9e5'});
        mg.messages.create('untamd.co.uk', {
        from: "Excited User <mailgun@sandbox-123.mailgun.org>",
        to: [recipientEmail],
        subject: "Your Horoscope",
        text: `Your horoscope is: ${horoscopeDescription}`,
        html: `<h1>Your horoscope is: ${horoscopeDescription}</h1>`
    })
    .then(msg => console.log(msg)) // logs response data
    .catch(err => console.log(err)); // logs any error
  },
  changeResultTop() {
    const resultElement = document.getElementById("result");
    if (window.innerWidth < 768) {
        resultElement.style.top = "30vh";
    } else {
        resultElement.style.top = "20vh";
    }
},
    rotateWheel() {
    this.attempts++;
        event.preventDefault();
        let inputNumber = document.querySelector("#input-day").value;
        let numberIndex = Number(inputNumber)-1;
        let numberRotateAngle = (360 / 31) * (numberIndex);
        this.numberWheel.style.transform = `rotate(-${numberRotateAngle}deg)`;

        let inputMonth = document.querySelector("#input-month").value;
        let monthIndex = this.months.indexOf(inputMonth);
        let monthRotateAngle = (360 / 12) * monthIndex;
        this.monthWheel.style.transform = `rotate(-${monthRotateAngle}deg)`;

        let signs = [
        { sign: 'Capricorn', startDate: '12-22', endDate: '01-19',
        constellationPath:'img/cons/capricorn.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Aquarius', startDate: '01-20', endDate: '02-18',
        constellationPath:'img/cons/aquarius1.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'an'},
        { sign: 'Pisces', startDate: '02-19', endDate: '03-20',
        constellationPath:'img/cons/pisces.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Aries', startDate: '03-21', endDate: '04-19',
        constellationPath:'img/cons/aries.svg',
        symbolPath:'assets/img/Star Sign Packs/Aries/aries-silhouette.svg',
        article:'an'},
        { sign: 'Taurus', startDate: '04-20', endDate: '05-20',
        constellationPath:'img/cons/taurus.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Gemini', startDate: '05-21', endDate: '06-20',
        constellationPath:'img/cons/gemini.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Cancer', startDate: '06-21', endDate: '07-22',
        constellationPath:'img/cons/cancer.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Leo', startDate: '07-23', endDate: '08-22',
        constellationPath:'img/pendants/leo.webp',
        symbolPath:'img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Virgo', startDate: '08-23', endDate: '09-22',
        constellationPath:'img/cons/virgo.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Libra', startDate: '09-23', endDate: '10-22',
        constellationPath:'img/cons/libra.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Scorpio', startDate: '10-23', endDate: '11-21',
        constellationPath:'img/cons/scorpio.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'},
        { sign: 'Sagittarius', startDate: '11-22', endDate: '12-21',
        constellationPath:'img/cons/sagittarius.svg',
        symbolPath:'assets/img/Star Sign Packs/Capricorn/capricorn-silhouette.svg',
        article:'a'}
        ];

        for (var i = 0; i < signs.length; i++) {
        var startDateArray = signs[i].startDate.split('-');
        var endDateArray = signs[i].endDate.split('-');

        if ((monthIndex+1 == startDateArray[0] && numberIndex+1 >= startDateArray[1]) || (monthIndex+1 == endDateArray[0] && numberIndex+1 <= endDateArray[1])) {
            document.getElementById('you_are').innerText = 'You are '+signs[i].article+' ...';
            document.getElementById('sign').innerText = signs[i].sign;
            document.getElementById('constellation').src = signs[i].constellationPath;
            let rotationDegrees = i * (360 / signs.length);
            this.zodiacNameWheel.style.transform = `translate(-50%, -50%) rotate(${360 - rotationDegrees}deg)`;
            this.zodiacSignWheel.style.transform = `translate(-50%, -50%) rotate(${-rotationDegrees}deg)`;
            this.zodiacSymbolWheel.style.transform = `translate(-50%, -50%) rotate(${360 - rotationDegrees}deg)`;

            break;
            }
        }
    },
handleSubmit(){
    if (this.attempts >= 2) {
        this.showCaptcha = true;
    }
    if (this.showCaptcha && !this.isCaptchaCompleted()) {
        alert('Please complete the CAPTCHA.');
        return; // Exit the function if CAPTCHA is not completed
    }
    if (this.month === 'Select Month') {
        this.monthWarning = true;
        return; // Exit the function if no month has been selected
    } else {
        this.monthWarning = false;
    }
    if (!this.isValidEmail(this.email)) {
        this.emailWarning = true;
        return; // Exit the function if the email is not valid
    } else {
        this.emailWarning = false;
    }
    this.changeResultTop();
    this.rotateWheel();
    this.horoscope();
}
  }
}
</script>
<style>
/* Hide by default */
.mobile-only {
    display: none;
}

.warning {
    border-color: red !important; /* change the border color of the month select box */
    color: red; /* change the text color */
}

.warning-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

input[type="button"].select:hover,
input[type="submit"].select:hover {
    cursor: pointer;
}
/* Show only on mobile view */
@media (max-width: 768px) {
    .mobile-only {
        display: none;
    }
}

@media (min-height: 1800px) {
    .mobile-only {
        display: none;
    }
}

</style>