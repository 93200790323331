<template>
     <div id="result">
        <h2 id="you_are"></h2>
        <h1 style="display:inline-block" id="sign"></h1><span v-if="computeLoading">     (wait for you results)</span>

        <p v-if="!intro && !description" id="content">Discover the wonders of the cosmos by entering your birthday! Not only will you uncover your Zodiac Sign and its corresponding Constellation stars, but our skilled fortuneteller will also provide you with a personalised horoscope for the moment. Dive into the mysteries of the stars and see what the universe has in store for you right now!</p>
            <div v-if="computeLoading" class="loading-container">
      <!-- <video style="border-radius:50%; margin:auto; border: solid #EFE9D7 2px" src="/img/fortuneteller.webp.gif.mp4" type="video/mp4" autoplay loop muted playsinline alt="Loading..." /> -->
      <!--<img style="border-radius:50%; margin:auto; border: solid #EFE9D7 2px" src="/img/santa1.gif" alt="this slowpoke moves" width="300"/>-->
    <MysteryLoader/>
    </div>
        <p v-if="!intro && description" id="content" v-html="description"></p>
        <p v-if="intro" id="content_intro">{{ intro }}</p>
        <h3 v-if="paragraph_one">Your Connection With Today</h3>
        <p v-if="paragraph_one" id="content_p1">{{ paragraph_one }}</p>
        <h3 v-if="paragraph_two">Birthdays & Events</h3>
        <p v-if="paragraph_two" id="content_p2">{{ paragraph_two }}</p>
        <h3 v-if="paragraph_three">Your Zodiac Sign and Symbol</h3>
        <p v-if="paragraph_three" id="content_p3">{{ paragraph_three }}</p>
        <h3 v-if="paragraph_four">Your Horoscope</h3>
        <p v-if="paragraph_four" id="content_p4">{{ paragraph_four }}</p>

<div v-if="intro || paragraph_one || paragraph_two || paragraph_three || paragraph_four" id="social-media-buttons">
    <a href="https://www.facebook.com/sharer/sharer.php?u=https://zodiac.untamd.co.uk" target="_blank"><i class="fab fa-facebook-f"></i></a>
    <a href="https://twitter.com/share?url=https://zodiac.untamd.co.uk&text=Find%20your%20Zodiac%20and%20Horoscope" target="_blank"><i class="fab fa-twitter"></i></a>
    <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://zodiac.untamd.co.uk" target="_blank"><i class="fab fa-linkedin-in"></i></a>
    <a href="https://api.whatsapp.com/send?text=Find%20your%20Zodiac%20and%20Horoscope:%20https://zodiac.untamd.co.uk" target="_blank"><i class="fab fa-whatsapp"></i></a>
    <a href="https://telegram.me/share/url?url=https://zodiac.untamd.co.uk&text=Find%20your%20Zodiac%20and%20Horoscope" target="_blank"><i class="fab fa-telegram-plane"></i></a>
    <button onclick="copyToClipboard()"><i class="fas fa-copy"></i></button>
</div>


<!-- <button id="share-button" class="select" style="text-align: center;
    font-size: 20px;
    padding: 5px;
    border-style: solid;
    border-radius: 8px;
    border-color: #EFE9D7;
    cursor:pointer;
    margin: 0 2% 2% 0;
    width: 75%;">Share</button> -->
    </div>
</template>
<script>
import MysteryLoader from './MysteryLoader.vue';

document.querySelectorAll('#social-media-buttons a').forEach(button => {
    button.addEventListener('click', function(e) {
        e.preventDefault();
        window.open(this.href, '_blank', 'width=600,height=400,noopener');
    });
});
document.querySelectorAll('#social-media-buttons a').forEach(button => {
    button.addEventListener('click', function(e) {
        e.preventDefault();
        window.open(this.href, '_blank', 'width=600,height=400,noopener');
    });
});


export default {
  components:{
      MysteryLoader},
    computed:{
        computeLoading(){
        let load = this.$store.getters.loading;
        return load
        },
        intro() {
            let desc = this.$store.getters.description;
            return desc ? desc.introduction : null;
        },
        paragraph_one() {
            let desc = this.$store.getters.description;
            return desc ? desc.paragraph_1 : null;
        },
        paragraph_two() {
            let desc = this.$store.getters.description;
            return desc ? desc.paragraph_2 : null;
        },
        paragraph_three() {
            let desc = this.$store.getters.description;
            return desc ? desc.paragraph_3 : null;
        },
        paragraph_four() {
            let desc = this.$store.getters.description;
            return desc ? desc.paragraph_4 : null;
        },
        description() {
            let desc = this.$store.getters.description;
            //if (desc){
//
            // Encode less-than and greater-than signs (Basic sanitization)
  //          desc = desc.replace(/</g, "&lt;").replace(/>/g, "&gt;");
//
            // replace numbers followed by a dot with <br> tags
  //          desc = desc.replace(/([1-4]\.\s)/g, "<br>");
            //}
            return desc
        }
    }
}
</script>